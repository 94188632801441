(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:viewResource
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <nav-bar></nav-bar>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('viewResource', viewResource);

  function viewResource() {
    return {
      restrict: 'EA',
      scope: {
        resource: '=',
        resourceNode: '=',
        moduleId: '='
      },
      templateUrl: 'components/view-resource/view.tpl.html',
      replace: false,
      controllerAs: 'view',
      controller: 'ViewCtrl'
    };
  }
}());
